<script setup>
import Layout from "../../layouts/main.vue";
import mushroom from "cem-primary-api";
import { useRoute, useRouter } from "vue-router";
import { ref } from "vue";
import { FormInstance } from "element-plus";
import toastr from "toastr";
import { useI18n } from "vue-i18n";

import { langWeb } from "../../stores/lang";
const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase());

// data khai báo
import MethodService from "../../service/MethodService";
import Data from "./dataFormNhomQuyen";
const { t } = useI18n();
const ruleFormRef = ref(FormInstance);
const dataForm = reactive({
  value: MethodService.copyObject(Data.dataForm),
});
const loadingBtn = ref(false);
const validateFlag = ref(false);
const radioGroup = ref("radioProvice");

// pinia store
import { DVHCStore } from "../../stores/getDVHC";
import { onMounted, reactive } from "vue";
const dvhc_store = DVHCStore();

const router = useRoute();
const routerPush = useRouter();

// validate form
import Validate from "../../service/ValidService";
import LanguageService from "../../service/LanguageService";
import { watch } from "vue";

const rulesForm = reactive({
  name: [Validate.required, Validate.checkMaxLength(20)],
  province_ids: [Validate.requiredChange],
  net_xs: [Validate.requiredChange],
  roles: [Validate.requiredChange],
});
const optionsNetX = ref([]);
const get_all_net_X = async () => {
  try {
    const response = await mushroom.probe.views.get_net_xsAsync();
    optionsNetX.value = [];
    response.result?.forEach((item) => {
      optionsNetX.value.push({
        value: item.net_x,
        label: item.net_x,
      });
    });
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
// thêm mới
const addNewGroup = (formEl) => {
  validateFlag.value = true;
  if (!formEl) return;
  formEl.validate(async (valid) => {
    loadingBtn.value = true;
    if (!valid) return;
    const group_object = {
      name: dataForm.value.name.trim(),
      roles: dataForm.value.roles == "Edit" ? ["View", "Edit"] : ["View"],
    };
    if (radioGroup.value === "radioProvice") {
      (group_object.province_ids = dataForm.value.province_ids),
        (group_object.net_xs = []);
    } else {
      (group_object.province_ids = []),
        (group_object.net_xs = dataForm.value.net_xs);
    }
    try {
      const newId = await mushroom.group.createAsync(group_object);
      if (newId) {
        toastr.success(t("toastr_add_success"));
      }
      routerPush.go(-1);
    } catch (error) {
      if (error.meta.field === "$unique_key") {
        toastr.error(t("toastr_group_existed"));
      }
    } finally {
      loadingBtn.value = false;
    }
  });
};

// cập nhật
const updateNewGroup = (formEl) => {
  validateFlag.value = true;
  if (!formEl) return;
  formEl.validate(async (valid) => {
    loadingBtn.value = true;
    if (!valid) return;
    const group_object = {
      id: router.params.id,
      name: dataForm.value.name.trim(),
      roles: dataForm.value.roles == "Edit" ? ["Edit", "View"] : ["View"],
    };
    if (radioGroup.value === "radioProvice") {
      (group_object.province_ids = dataForm.value.province_ids),
        (group_object.net_xs = []);
    } else {
      (group_object.province_ids = []),
        (group_object.net_xs = dataForm.value.net_xs);
    }
    try {
      const newId = await mushroom.group.partialUpdateAsync(group_object);
      if (newId.result === router.params.id) {
        toastr.success(t("toastr_update_group_successfully"));
        routerPush.go(-1);
      }
    } catch (error) {
      if (error.meta.field === "$unique_key") {
        toastr.error(t("toastr_group_existed"));
      }
    } finally {
      loadingBtn.value = false;
    }
  });
};
// function lấy thông tin chi tiết quyền hạn
async function getGroupByID() {
  mushroom.group.invalidCache();
  try {
    const response = await mushroom.group.findByIdAsync({
      id: router.params.id,
      fields: "id, name, province_ids, net_xs, roles",
    });

    if (response.result.roles?.includes("Edit")) response.result.roles = "Edit";
    dataForm.value = response.result;
    if (
      response.result.net_xs.length > 0 &&
      response.result.province_ids.length === 0
    ) {
      radioGroup.value = "radioNetX";
    } else if (
      response.result.province_ids.length > 0 &&
      response.result.net_xs.length === 0
    ) {
      radioGroup.value = "radioProvice";
    } else radioGroup.value = "radioProvice";
  } catch (e) {
    MethodService.showError(e.code);
  }
}

watch(
  () => lang_web.showLang,
  () => {
    lang.value = lang_web.showLang.toLocaleUpperCase();
    LanguageService.lang = LanguageService[lang.value];
    if (validateFlag.value == true) {
      rulesForm.value = MethodService.copyObject(rulesForm);
    }
  }
);

onMounted(() => {
  dvhc_store.getProvince();
  get_all_net_X();
  if (router.name === "ThemMoiNhomQuyen") {
    radioGroup.value = "radioProvice";
  } else getGroupByID();
  setTimeout(() => {
    let activeMenu = document
      .querySelector("#dvhc")
      .querySelector('[href="/group"]');
    console.log("activeMenu", activeMenu);
    activeMenu.classList.add("active");
    let parentCollapseDiv = activeMenu.closest(".collapse.menu-dropdown");
    if (parentCollapseDiv) {
      parentCollapseDiv.classList.add("show");
      parentCollapseDiv.parentElement.children[0].classList.add("active");
      parentCollapseDiv.parentElement.children[0].setAttribute(
        "aria-expanded",
        "true"
      );
      if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
        parentCollapseDiv.parentElement
          .closest(".collapse")
          .classList.add("show");
        if (
          parentCollapseDiv.parentElement.closest(".collapse")
            .previousElementSibling
        )
          parentCollapseDiv.parentElement
            .closest(".collapse")
            .previousElementSibling.classList.add("active");
      }
    }
  }, 500);
});
</script>

<template>
  <Layout>
    <div class="card">
      <div class="card-header">
        <h3 v-if="router.name === 'ThemMoiNhomQuyen'">
          {{ $t("t-add-group") }}
        </h3>
        <h3 v-else>{{ $t("t-update-group") }}</h3>
      </div>
      <div class="card-body">
        <div class="main-body">
          <el-form
            ref="ruleFormRef"
            :model="dataForm.value"
            status-icon
            :rules="rulesForm"
            label-width="200px"
            class="form-user"
          >
            <el-form-item>
              <el-radio-group v-model="radioGroup">
                <el-radio label="radioProvice">{{
                  $t("t-roles-province")
                }}</el-radio>
                <el-radio label="radioNetX">{{ $t("t-roles-netx") }}</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item :label="$t('t-name-group-roles')" prop="name">
              <el-input
                type="text"
                autocomplete="off"
                v-model="dataForm.value.name"
                @change="dataForm.value.name = dataForm.value.name.trim()"
                :placeholder="$t('t-name-group-roles')"
              />
            </el-form-item>
            <el-form-item
              :label="$t('t-dvhc-tinh')"
              prop="province_ids"
              v-if="radioGroup === 'radioProvice'"
            >
              <el-select
                v-model="dataForm.value.province_ids"
                filterable
                multiple
                :placeholder="$t('t-place-dvhc-tinh')"
                style="width: 100%"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
              >
                <el-option
                  v-for="item in dvhc_store.provinceData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item
              :label="$t('t-list-netX')"
              prop="net_xs"
              v-if="radioGroup === 'radioNetX'"
            >
              <el-select
                v-model="dataForm.value.net_xs"
                multiple
                filterable
                allow-create
                default-first-option
                :reserve-keyword="false"
                :placeholder="$t('t-place-select-or-new-netX')"
                style="width: 100%"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
              >
                <el-option
                  v-for="item in optionsNetX"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item :label="$t('t-group-roles')" prop="roles">
              <el-select
                v-model="dataForm.value.roles"
                filterable
                :placeholder="$t('t-place-group-roles')"
                style="width: 100%"
                :no-match-text="$t('t-match-data')"
                :no-data-text="$t('t-no-data')"
              >
                <el-option
                  v-for="item in Data.roles"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <div class="text-center">
              <button
                type="button"
                class="btn btn-soft-secondary btn-border mx-3"
                @click="routerPush.go(-1)"
              >
                {{ $t("t-back") }}
              </button>
              <el-button
                type="button"
                style="height: 38px"
                class="btn btn-secondary btn-border"
                @click="addNewGroup(ruleFormRef)"
                v-if="router.name === 'ThemMoiNhomQuyen'"
                :loading="loadingBtn"
              >
                {{ $t("t-add") }}
              </el-button>

              <el-button
                type="button"
                style="height: 38px"
                class="btn btn-secondary btn-border"
                @click="updateNewGroup(ruleFormRef)"
                :loading="loadingBtn"
                v-else
              >
                {{ $t("t-update") }}
              </el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
.el-radio {
  margin-bottom: 0;
}
</style>
